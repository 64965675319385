
/* make variables */
:root {
  --lightgray: #D9D9D9;
  --gray: #9c9c9c;
  --orange: #f48915;
  --darkGrey: #464D53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
  --appColor:  #3c3f45;
}

.App {
 
  background: black;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
::-webkit-scrollbar{
  display: none;
}
.stroke-text{
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  border-color: white;
  -webkit-text-stroke-width:1px ;
  -webkit-text-stroke-color:white ;
}

.blur{
  background: rgb(43, 36, 24);
  position: absolute;
  border-radius: 50%;
  filter: blur(170px);
  z-index: -9;
  
}