

.header{
    display: flex;
    justify-content: space-between;
}
.navbar-brand{
    margin-left: -4rem;
    color: aliceblue;
text-transform: uppercase;
}
.navbar-brand>:nth-child(2
    ){
        font-size: 10px;
        margin-left:1rem;
        margin-top: -1.8rem;
        letter-spacing: 1rem;
    }
.navbar-brand>:nth-child(2
    ):hover{
        color: yellow;
    }
.logo{
    width: 4rem;
    height: 3rem;
}
.header-menu{
    margin-top: 1.1rem;
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
}

.header-menu>li:hover{
    color: var(--orange);
    cursor: pointer;
}
@media screen and (max-width:768px) {
    .header>:nth-child(2){
        position: fixed;
        right: 4rem;
        z-index: 99;
    }
    .navbar-brand{
        margin-left: -4rem;
        color: aliceblue;
    text-transform: uppercase;
    font-size: 1rem;
    }
    .navbar-brand>:nth-child(2
    ){
        font-size: 10px;
        margin-left:-2px;
        margin-top: -1.8rem;
        letter-spacing: 5px;
    }
    .navbar-brand>:nth-child(2
    ):hover{
        color: yellow;
    }

    .header-menu{
        position: fixed;
        flex-direction: column;
        background-color:black;
        padding: 2rem 1rem;
    }
    
}