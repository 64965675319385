.App {
}
.age{
  color: white;
  margin-top: 6rem;
  margin-left: 80rem;
 
}
.age>:nth-child(1){
  
  font-size: 3rem;
  color:var(--orange) ;
}
.age>:nth-child(3){
  width: 85px;
  border: 1px solid rgb(248, 232, 0);
}
.age>:nth-child(4){
  display: flex;
  margin-top: -3rem;
  margin-top: 1rem;
  margin-left: 2px;
  font-size: 5rem;
}
.header {
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 1rem;
  padding-bottom: 0.3rem;
  display: flex;
  flex-direction: row;
  margin-bottom: 2.18rem;
}
.image-sec {
  display: flex;
  flex-direction: row;
}
.hero-image {
  display: flex;
  object-fit: cover;
  width: 25rem;
  height: 30rem;
  filter: brightness(0.7);
  border: 5px #030303;
  box-shadow: 6px 6px 16px #1d1c1c;
  margin-top: -18rem;
}
.background-1 {
  display: flex;

  height: 30rem;
  width: 25rem;
  background-color: var(--orange);
  margin-left: -23.5rem;
  margin-top: -16.5rem;
}
.background-2 {
  display: flex;
  height: 30rem;
  width: 25rem;
  background-color: transparent;
  border: 3px solid #f5ff008f;
  margin-left: -28rem;
  margin-top: -19.5rem;
}
.hi {
  padding: 1rem 11.8rem;
  margin-top: -18.4rem;
  display: flex;
  position: absolute;
  color: white;
  font-size: 1.3rem;
}
.text {
  position: absolute;
    display: flex;
    flex-direction: row;
    padding-top: 5rem;
    padding-left: 39.2rem;
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: bold;
}
.text > :nth-child(1) {
  font-size: 3rem;
  margin-top: -0.01rem;
  color: var(--appColor);
}
.whoiam {
  padding: 0.7rem 2rem;

  color: #ffee00;
}
.subtext {
  position: relative;
  right: 18.2rem;
  top: 19rem;
  position: absolute;
  text-align: justify;
  color: white;
  width: 500px;
  align-items: center;
}
.subtext > span {
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  gap: 2px;
}
.btn {
  margin-top: 4rem;
  margin-left: 0rem;
  gap: 3px;
  color: white;
  font-weight: 450;
  font-size: 15px;
}
.btn-user {
  color: rgb(247, 255, 255);
  padding: 0.5rem 16px;
}
.btn:hover {
  transition: all 0.2s;
  background-color: var(--appColor);
  color: white;
  border-color: var(--appColor);
  transform: scale(1.01);
}
.btn-2 {
  padding: 0.5rem 12px;
  margin-top: 4rem;
  margin-left: 3rem;
  gap: 3px;
  color: white;
  font-weight: 450;
  font-size: 15px;
  background-color: #030303;
  border: 1px solid #ffc107;
}
.btn-suit {
  color: rgb(247, 255, 255);
  padding-right: 10px;
}
.btn-2:hover {
  transition: all 0.2s;
  background-color: #ffc107;
  color: white;
  transform: scale(1.01);
}
.right-j{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  
}
.email-container{
  display: flex;
  position: absolute;
  height: 3rem;
  gap: 3rem;
  padding: 1rem 1rem;
  background-color: transparent;
  margin-right: -7.5rem;
  top: 36rem;
 
}
.btn-j{
  color: white;
  background-color: var(--orange);
  margin-top: -0.2rem;
  height: fit-content;
  padding-top: 0.2rem 0.5rem;
  width: 6rem;
  padding-left: 1rem;
    margin-left: -6px;
}
.btn-j:hover{
  cursor: pointer;
  transform: scale(1.2);
  transition: 0.3s;
  border-radius: 10%;
}
.email-container>input{
  background-color: transparent;
  height: 2rem;
  border: none;
  outline: none;
  color: var(--lightgray);
}
::placeholder{
  background-color: transparent;
  color: var(--lightgray);
}
@media screen and (max-width: 768px) {
  .App {
    height: 1200px;
    
  }
  
  .header {
    flex-direction: column;
  }
  .hi {
    padding: 1rem 7.3rem;
    top: 5rem;
    margin-left: -22rem;
    display: flex;
    position: absolute;
    color: white;
    font-size: 1.3rem;
  }
  .text {
    position: absolute;
    flex-direction: column;
    margin-top: -3.8rem;
    align-items: baseline;
    left: -36.3rem;
    text-transform: uppercase;
    font-weight: bold;
  }
  .text > :nth-child(1) {
    display: flex;
    color: var(--appColor);
    font-size: 2.5rem;
    /* margin-left: -33rem; */
    position: absolute;
    z-index: 100;
  }
  .whoiam {
    display: flex;
    
    padding: 3.3rem 0rem;
    /* margin-left: -34rem; */
    position: absolute;
    font-size: 1rem;
    width: fit-content;
    color: #fff100;
    
  }
  .subtext {
    display: flex;
    position: relative;
    margin-right: -255px;
    
    margin-top: -9rem;
    line-height: 10px;
    position: absolute;
    text-align: justify;
    color: white;
    width: 320px;
    
    flex-direction: column;
    
  }
  .subtext > span {
    font-size: 14px;
    line-height: 32px;
    font-weight: 450;
    font-family: "Open Sans", sans-serif;
    margin-top: 30rem;
   
  }
  .hero-image {
    position: relative;
    width: 17rem;
    height: 20rem;
    left: -0.5rem;
    top: -27rem;
  }
  .background-1 {
    display: flex;
    height: 20rem;
    width: 17rem;
    background-color: var(--orange);
    margin-top: -43.5rem;
    margin-left: -16.2rem;
  }
  .background-2 {
    border: none;
  }
  .btn-2 {
    margin-top: 2rem;
    margin-left: 0rem;
    gap: 3px;
    margin-bottom: 3rem;
    color: white;
    height: 2.6rem;
    font-weight: 450;
    font-size: 15px;
    align-self: baseline;
    background-color: #030303;
    border: 1px solid #ffc107;
  }
  .btn-user{
   
    align-self: baseline;
  }
  .background-2 {
    display: flex;
    height: 30rem;
    width: 25rem;
    background-color: transparent;
    margin-left: -27.5rem;
    margin-top: -1.1rem;
  }
  .email-container{
    display: flex;
    flex-direction: row;
    position: absolute;
    margin-top:29rem;
    height: 3rem;
    gap: 0rem;
    padding: 1rem 0rem;
    background-color: transparent;
    margin-right: 80px;
   position: absolute;
   
  }
  .btn-j{
    color: white;
    background-color: var(--orange);
    margin-top: -0.2rem;
    height: fit-content;
    padding-top: 0.2rem 0.5rem;
    width: 5rem;
    margin-left: 12rem;
    position: absolute;
  }
  .age{
    color: white;
    margin-top: 47rem;
    margin-left: 18rem;
   
  }
  .age>:nth-child(1){
    margin-top: 10px;

    font-size: 1.4rem;
    color:var(--orange) ;
  }
  .age>:nth-child(3){
    width: 40px;
    border: 1px solid rgb(248, 232, 0);
  }
  .age>:nth-child(4){
    display: flex;
    margin-top: -3rem;
    margin-top: 1rem;
    margin-left: 2px;
    font-size: 2rem;
  }
}
